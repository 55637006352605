// Global variable to store the total offset height of elements with data-document-scroll-height
let dataScrollHeightTotal = 0;

// Calculate total offset height of [data-document-scroll-height] elements
function calculateDataScrollHeight() {
    const elements = document.querySelectorAll('[data-document-scroll-height]');
    let totalHeight = 0;
    elements.forEach(element => {
        totalHeight += element.offsetHeight;
    });
    dataScrollHeightTotal = totalHeight;
}

// Update the body class based on scroll position
function updateBodyClassOnScroll() {
    window.g_scroll = window.scrollY; // Current scroll position
    const bodyElement = document.body;

    const documentHeight = document.documentElement.scrollHeight - dataScrollHeightTotal;
    const viewportHeight = window.innerHeight;

    if(documentHeight > viewportHeight) {
        if (window.g_scroll > 0) {
            bodyElement.classList.add('scroll');
        } else {
            bodyElement.classList.remove('scroll');
        }
    }
}

// Handle layout changes: recalculate heights and then update scroll class
function handleLayoutChanges() {
    calculateDataScrollHeight();
    updateBodyClassOnScroll();
}

// As soon as the DOM is ready, handle layout changes so the class is added as early as possible
document.addEventListener('DOMContentLoaded', handleLayoutChanges);

// Recalculate and update on resize
window.addEventListener('resize', handleLayoutChanges);

// Update class on scroll
window.addEventListener('scroll', handleLayoutChanges);
